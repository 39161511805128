(function (initializeHotspotsQuickBuyAndWishListEvents, Swiper, undefined) {
    "use strict";

    function initializeQuickBuyAndWishListEvents() {
        if (typeof initializeHotspotsQuickBuyAndWishListEvents === "function") {
            initializeHotspotsQuickBuyAndWishListEvents("div.carImgDesc");
        }
    }

    function initSwiper(element) {
        var _ = new Swiper(element, {
            // Check Swiper to see if any children have been marked for lazy loading
            lazy: element.querySelector(".swiper-lazy") !== null,
            slidesPerView: 5,
            spaceBetween: 10,
            loop: true,
            autoplay: {
                delay: 2500,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                480: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
            threshold: 20,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }

    function showTab(index) {
        var switchElement = document.getElementById("switch" + index);
        switchElement.classList.remove("inActive");
        switchElement.classList.add("active");

        var tabElement = document.getElementById("tab" + index);
        tabElement.classList.remove("inActive");
        tabElement.classList.add("active");
    }

    function hideTab(index) {
        var switchElement = document.getElementById("switch" + index);
        switchElement.classList.remove("active");
        switchElement.classList.add("inActive");

        var tabElement = document.getElementById("tab" + index);
        tabElement.classList.remove("active");
        tabElement.classList.add("inActive");
    }

    var previousIndex = 1;

    function switchTab() {
        hideTab(previousIndex);
        var previousSwiperElement = document.getElementById("swiper" + previousIndex);
        if (previousSwiperElement.swiper.autoplay.running) {
            // Stop the previous Swiper
            previousSwiperElement.swiper.autoplay.stop();
        }

        var index = this.dataset.index;
        showTab(index);
        var swiperElement = document.getElementById("swiper" + index);
        if (swiperElement.swiper) {
            // Make sure that the Swiper is playing
            if (!swiperElement.swiper.autoplay.running) {
                swiperElement.swiper.autoplay.start();
            }
            // An update must be called to calculate the size of the Swiper
            swiperElement.swiper.update();
        } else {
            // Swiper hasn't previously been initialised
            initSwiper(swiperElement);
        }

        previousIndex = index;
    }

    function init() {
        initializeQuickBuyAndWishListEvents();

        // Initialise first Swiper if one is defined
        var swiperElement = document.getElementById("swiper1");
        if (swiperElement) {
            initSwiper(swiperElement);
        }

        var switches = document.querySelectorAll("ul.tabSwitch > li");
        Array.prototype.forEach.call(switches, function (item) {
            item.addEventListener("click", switchTab);
        });
    }

    (function ready() {
        if (document.readyState !== "loading") {
            init();
        } else {
            document.addEventListener("DOMContentLoaded", init);
        }
    })();
})(window.initializeHotspotsQuickBuyAndWishListEvents, window.Swiper);
